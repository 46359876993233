<template>
    <div class="storeList">
        <div class="storeItem" v-for="(item, index) in dataArr" :key="index">
            <div class="top1">
                <div class="left">
                    <van-image class="img" :src="item.volumePic" />
                </div>
                <div class="right">
                    <div class="title"><span class="tip1">套餐名：</span>{{ item.volumeName }}</div>
                    <div class="title"><span class="tip1">套餐价格：</span>{{ item.volumePrice }}</div>
                    <div class="flex-row title title1"><span class="tip1">启用状态：</span><span>{{ getStatusStr(item.status)
                            }}</span>
                        <div class="updateBtn" @click="updateBtnClick(item)"
                            v-if="item.status != 2 && isBeforeTime(item)">{{item.status==0?'禁用':'启用'}}</div>
                    </div>
                </div>
            </div>

            <div class="top2" v-if="item.money && item.money > 0">
                <div class="flex-row address"><span class="tip1 tip2">券额：</span><span class="tip3">¥{{ item.money
                        }}</span>
                </div>
                <div class="flex-row address"><span class="tip1 tip2">数量：</span><span class="tip3">{{ item.total
                        }}张</span>

                </div>
                <div class="flex-row address"><span class="tip1 tip2">剩余数量：</span><span class="tip3">{{ item.remainder
                        }}张</span>

                </div>
                <div class="flex-row address"><span class="tip1 tip2">券使用时间：</span><span
                        class="tip3">{{xxparseTime(item.startTime)}} ~
                        {{xxparseTime(item.endTime)}}</span>
                </div>
                <div class="line1"></div>

                <div class="btn-box">
                    <div class="b1" @click="toCouponDetail(item)">券的领取情况</div>
                </div>
            </div>


        </div>
        <div style="height:100px"></div>
        <div class="addBtnBox">
            <div class="addBtn" @click="addCouponClick">添加套餐</div>
        </div>

    </div>
</template>
<script>
import { Toast } from 'vant';
export default {
    data() {
        return {
            dataArr: [],
            quarmas: {
                pageNum: 1,
                pageSize: 10,
                adcId:null
            },
            beginTime: "开始时间",
            endTime: "结束时间",
            time: true,
            total: 0,

        };
    },
    created() {
        this.quarmas.adcId = this.$route.query.Id
    },
    mounted() {
        this.init();
    },
    methods: {
        addpage() {
            this.copy = true;
            this.quarmas.pageNum = this.quarmas.pageNum + 1;
            this.init();
        },
        init() {
            console.log('获取门店列表')

            this.$http.getAdcCouponList(this.quarmas).then((res) => {
                if (res.code == 200) {
                    this.dataArr = res.data
                }
            });
        },
        isBeforeTime(item) {
            if (!item.endTime) {
                return true
            }
            if (item.endTime <= new Date()) {
                return false
            }
            return true
        },
        getStatusStr(state) {
            if (state == 0) {
                return '已启用';
            }
            if (state == 1) {
                return '已禁用';
            }
            //2
            return '已删除'
        },

        toCouponDetail(item) {
            this.$router.push('/storeCouponDetail?storeAdcId=' + this.quarmas.adcId + '&volumeCode=' + item.volumeCode)
            
        },


        updateBtnClick(item) {
            var param = {
                id: item.id,
                status: item.status == 0 ? 1 : 0,
                adcId:item.adcId
            }
            this.$http.updateAdcCouponInfo(param).then((res) => {
                if (res.code == 200) {
                    console.log('111')
                    Toast.success('更新成功')
                    item.status = param.status;
                    // this.dataArr = this.dataArr
                }
            });
        },

        addCouponClick() {
            this.$router.push('/storeCouponEdit?storeAdcId=' + this.quarmas.adcId)
            
        }


    },
};
</script>
<style lang="scss" scoped>
.flex-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.storeList {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    background: #f8f8f8;

    .storeItem {
        margin: 0 auto;
        margin-top: 24px;
        width: 345px;
        background: white;
        border-radius: 8px;
        padding: 12px;
        box-sizing: border-box;
        .top1{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            
            .left{
                width: 100px;
                height: 100px;
                margin-right: 8px;
                .img{
                    width: 100%;
                    height: 100%;
                }
                flex-shrink: 0;

            }
            .right{
                flex: 1;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
            margin-bottom: 10px;
        }

        .tip1 {
            font-size: 14px;
            color: rgba(33, 33, 33, 1);
            line-height: 20px;
            font-weight: 450;
        }

        .title {
            font-size: 14px;
            line-height: 20px;
        }

        .address {
            margin-top: 5px;
            font-size: 13px;
            line-height: 20px;
            color: rgba(153, 153, 153, 1);

            .tip3 {
                flex: 1;
                min-width: 0;
            }

        }

        .line1 {
            width: 100%;
            height: 1px;
            background: #f8f8f8;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .addBtn {
            height: 25px;
            justify-content: center;
            align-items: center;
            line-height: 25px;
            font-size: 14px;

        }

        .btn-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 13px;
            width: 100%;
            .b1{
                width: 100px;
                height: 30px;
                color: rgba(21, 123, 250, 1);
                text-align: center;
                // background-color: rgba(21, 123, 250, 1);
                line-height: 30px;
                border-radius: 5px;
            }
        }

    }

    .title1{
        align-items: center;
        .updateBtn {
            width: 60px;
            height: 25px;
            border-radius: 5px;
            background-color: rgba(21, 123, 250, 1);
            margin-left: 10px;
            line-height: 25px;
            color: white;
            font-size: 10px;
            text-align: center;
        }
    }
}


.addBtnBox{
    position: fixed;
    width: 100%;
    bottom: 0px;
    bottom: env(safe-area-inset-bottom);
    left: 0px;
    background: #f8f8f8;
    .addBtn {
        width: 90%;
        height: 45px;
        border-radius: 8px;
        background-color: rgba(21, 123, 250, 1);
        margin-left: 5%;
        bottom: 0;
        color: white;
        font-size: 15px;
        text-align: center;
        line-height: 45px;
        margin-bottom: 20px;
        // padding-bottom: 
    }
}


</style>